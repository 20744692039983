import React from 'react';
import { formatCurrency } from '../lib/util';
import styles from './chart.module.scss';

interface Props {
  currentFees:number;
  borderlessFees:number;
  difference: number;
}
const SavingsChart = ({ currentFees, borderlessFees, difference}:Props) => {
  const borderlessSavingsRatio = borderlessFees/currentFees;
  const borderlessProfitRatio = 1-borderlessSavingsRatio;

  const slimFees:boolean = borderlessSavingsRatio < .2;
  const superSlimFees:boolean = borderlessSavingsRatio < .007

  return(
    <div>
      <h3 className={styles.title}>Average annual <span className={styles.legendCosts}>costs</span> and <span className={styles.legendSavings}>savings</span></h3>
      <div className={styles.chartWrapper}>
          <div className={styles.currentFees}>
            <p className={styles.barInfo}>{`${formatCurrency(currentFees)}`}</p>
          </div>
          <div className={styles.blFeeWrapper}>
            <div className={`${styles.profit} ${superSlimFees ? styles.slim : ''}`} style={{height: `${100*borderlessProfitRatio}%`}}>
              <h3 className={styles.barInfo}>Savings</h3>
              <p className={styles.barInfo}>{`+ ${formatCurrency(difference)}`}</p>
            </div>
            <div className={styles.blFees} style={{height: `${100*borderlessSavingsRatio}%`}}>
              {!slimFees &&
                <p className={styles.barInfo}>{`${formatCurrency(borderlessFees)}`}</p>
              }
            </div>
          </div>
      </div>
      <div className={styles.chartLabels}>
          <h3 className={styles.label}>Credit Card Processors</h3>
          <h3 className={styles.label}>border<span className={styles.less}>less&trade;</span></h3>
      </div>
    </div>
  )
}
export default SavingsChart;