import React from 'react';
import styles from './Input.module.scss'
import { formatCurrency } from '../../lib/util';
import InputWrapper from './InputWrapper';

interface Props {
  revenue: number;
  onChange: (e:any)=>void;
}
const RevenueField = ({revenue, onChange}:Props) => {
  const displayRevAsCurrency = formatCurrency(revenue);
  return(
    <InputWrapper
      fieldName='monthlyRevenue'
      label="Amount of monthly revenue from online or credit card-based systems:"
      component={(
        <>
          <input
            id="monthlyRevenue"
            className={styles.slider}
            type='range'
            min="10000"
            max="1000000"
            step="10000"
            value={!!revenue ? revenue : undefined}
            onChange={onChange}
          />
          <span className={styles.rangeValue}>{displayRevAsCurrency}</span>
        </>
      )}
    />
  );
};

export default RevenueField