import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import styles from './disclaimer.module.scss';

const DisclaimerCopy = () => (
  <div className={styles.content}>
    <p>
    The savings values shown above are for illustrative purposes only. The savings 
    of the business when adding borderless™ may differ. The total savings amount 
    is computed using the average credit card processing costs from leading players 
    like PayPal and Stripe. Average costs for local payments are considered at 
    2.9% and international at 4.4%. The calculation takes into account a variety 
    of factors including, but not limited to: (a) monthly volume, (b) number of 
    transactions, (c) payment types such as C2B or B2B, and (d) foreign exchange 
    rates. The calculator does not take into account setup fees, monthly fees, 
    or minimums, which borderless™ does not charge. If you have any questions, 
    please contact <a className={styles.link} href="mailto:support@getborderless.com">support@getborderless.com</a>.
    </p>
  </div>
)
const Disclaimer = () => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(!visible);
  return(
  <Tippy content={<DisclaimerCopy/>} visible={visible}>
    <button className={styles.disclaimerBtn} onClick={handleClick}>{!visible ? 'Learn More' : 'Hide Explanation'}</button>
  </Tippy>
)};

export default Disclaimer