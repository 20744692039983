import React from 'react';
import styles from './Input.module.scss'
import InputWrapper from './InputWrapper';

interface Props {
  qty: number;
  onChange: (vals:any)=>void;
}

const formatNumber = (qty:number):string => {
  return new Intl.NumberFormat().format(qty);
}

const MonthlyPaymentsField = ({qty, onChange}:Props) => (
  <InputWrapper
      fieldName='monthlyTransactions'
      label="Average number of payments processed monthly:"
      component={(
        <>
          <input
            id="monthlyTransactions"
            className={styles.slider}
            type='range'
            min="10"
            max="1000"
            step="10"
            value={!!qty ? qty : undefined}
            onChange={onChange}
          />
          <span className={styles.rangeValue}>{formatNumber(qty)}</span>
        </>
      )}
    />
);

export default MonthlyPaymentsField;