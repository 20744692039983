import React, { useState, useEffect } from 'react';
import './App.css';
import Results from './Components/CalculatedSavings';
import FormFields from './Components/FormFields';
import './styles.scss';
import Disclaimer from './Components/Disclaimer';
import logo from './img/borderlessLogo-02.png';

const App=()=> {
  const [clients, setClients]=useState<'businesses'|'individuals'|null>(null);
  const [intlPercent, setIntlPercent] = useState(0);
  const [revenue, setRevenue] = useState(10000);
  const [avgMonthlyPayments, setAvgMonthlyPayments] = useState(10);

  const handleClientelleChange=(event:any):void=>{
    setClients(event.target.value);
  }

  const handleIntlPercentChange=(event:any):void=>{
    setIntlPercent(event.target.value);
  }

  const handleRevenueChange = (event:any):void=>{
    setRevenue(event.target.value);
  }

  const handleAvgMonthlyPaymentsChange = (event:any):void=>{
    setAvgMonthlyPayments(event.target.value)
  }

  useEffect(() => {
    const root = document.getElementById('root');
    const AppHeight = root?.offsetHeight;
    window.parent.postMessage({AppHeight}, 'https://getborderless.com'); //send the message and target URI
  });

  return (
    <div className="App">
      <div className="AppHeader">
        <a href='http://getborderless.com'>
          <img src={logo} className="logo" alt="borderless logo"/>
        </a>
        <h1 className='title' style={{fontSize:'48px'}}>
          Savings Calculator
        </h1>
        <p style={{fontWeight: 600}}>
          Increase your bottom line by using borderless&trade;
        </p>
      </div>
      <FormFields
        clients={clients}
        intlPercent={intlPercent}
        avgMonthlyPayments={avgMonthlyPayments}
        revenue={revenue}
        onChangeAvgMonthlyPayments={handleAvgMonthlyPaymentsChange}
        onChangeRevenue={handleRevenueChange}
        onChangeClients={handleClientelleChange}
        onChangeIntlPercent={handleIntlPercentChange}
      />
      <Results
        clients={clients}
        revenue={revenue}
        intlPercent={intlPercent}
        numberTransactions={avgMonthlyPayments}
      />
      <Disclaimer/>
    </div>
  );
}

export default App;
