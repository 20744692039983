import React from 'react';
import styles from './button.module.scss'

const GetStartedButton = () => (
  <div className={styles.buttonWrapper}>
    <a href="https://secure.getborderless.com/user-register" target="_blank" rel='noopener noreferrer'>
      <button 
        className={styles.button}
      >
        Get Started
      </button>
    </a>
  </div>
)

export default GetStartedButton;