import React from 'react';
import ClientSelector from './InputFields/ClientSelector';
import RevenueField from './InputFields/RevenueField';
import MonthlyPaymentsField from './InputFields/MonthlyPaymentsField';
import IntlPercentSlider from './InputFields/IntlPercentSlider';

interface Props {
  clients: 'businesses'|'individuals'|null;
  intlPercent: number;
  revenue: number;
  avgMonthlyPayments: number;
  onChangeClients: (e:any)=>void;
  onChangeRevenue: (e:any)=>void;
  onChangeIntlPercent: (e:any)=>void;
  onChangeAvgMonthlyPayments: (e:any)=>void;
}
const FormFields = ({
  clients,
  intlPercent,
  revenue,
  avgMonthlyPayments,
  onChangeAvgMonthlyPayments,
  onChangeClients,
  onChangeIntlPercent,
  onChangeRevenue,
}:Props) => (
  <>
    <ClientSelector
      clientType={clients}
      onChange={onChangeClients}
    />
    <RevenueField
      revenue={revenue}
      onChange={onChangeRevenue}
    />
    <MonthlyPaymentsField
      qty={avgMonthlyPayments}
      onChange={onChangeAvgMonthlyPayments}
    />
    <IntlPercentSlider
      percent={intlPercent}
      onChange={onChangeIntlPercent}
    />
  </>
);

export default FormFields;