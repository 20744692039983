import React from 'react';
import styles from './Input.module.scss'
import InputWrapper from './InputWrapper';
// import './Input.module.scss';

interface Props {
  clientType: 'businesses'|'individuals'|null;
  onChange: (e:any)=>void;
}
const ClientSelector = ({clientType, onChange}:Props) => (
  <InputWrapper
    label="Are your customers primarily businesses or individuals?"
    fieldName="clientType"
    component={
      (<div className={styles.clientType}>
        <div>
          <input 
            className={styles.client}
            type="radio"
            checked={clientType === 'businesses'}
            name="Client Type"
            value='businesses'
            onChange={onChange}
          />
            <span>Businesses</span>
        </div>
        <div>
          <input 
            className={styles.client}
            type="radio"
            checked={clientType === 'individuals'}
            name="Client Type"
            value='individuals'
            onChange={onChange}
          />
            <span>Individuals</span>

        </div>
      </div>)
    }
  />
);

export default ClientSelector