import React from 'react';
import styles from './Input.module.scss';

interface Props {
  component: JSX.Element;
  fieldName: string;
  label: string;
}
const InputWrapper = ({component, fieldName, label}:Props) => (
  <div className={styles.inputWrapper}>
    <label className={styles.inputLabel} htmlFor={fieldName}>{label}</label>
    <div className={styles.inputContainer}>
      {component}
    </div>
  </div>
  )

export default InputWrapper