import React from 'react';
import { calculateCurrentCosts, calculateBorderlessCost, formatCurrency } from '../lib/util';
import SavingsChart from './Chart';
import GetStartedButton from './GetStarted';

const calcAnnualFromMonthly=(amt:number)=>{
  return amt * 12;
}

const formattedSavings = (amt:number):JSX.Element=>{
  const annualSavings = calcAnnualFromMonthly(amt);
  const formatted = formatCurrency(annualSavings)
  return (<span style={{fontWeight: 'bold', color: "#21b8a2", padding: '.5rem', fontFamily: 'Panton'}}>{formatted}</span>)
}

 interface Props {
  clients: 'businesses'|'individuals'|null;
  revenue: number;
  intlPercent: number;
  numberTransactions: number;
}

const Results=({clients, revenue, intlPercent, numberTransactions}:Props)=>{
  if (!clients
    || !revenue
    || !intlPercent
    || !numberTransactions) return (
      <h2>
        To see your savings, please enter your business information.
      </h2>
    )

  const currentCosts:number = calculateCurrentCosts(revenue, intlPercent);
  const borderlessCosts:number = calculateBorderlessCost({revenue, clients, intlPercent, numberTransactions})
  const difference:number =  currentCosts - borderlessCosts;

  return(
    <div style={{width:'100%', maxWidth:'720px'}}>
      <SavingsChart
        currentFees={calcAnnualFromMonthly(currentCosts)}
        borderlessFees={calcAnnualFromMonthly(borderlessCosts)}
        difference={calcAnnualFromMonthly(difference)}
      />
      <h2 style={{textAlign:'center', fontSize: '36px'}}>
        Save up to 
        {formattedSavings(difference)}
        per year!
      </h2>
      <GetStartedButton/>
    </div>
  )
}

export default Results;