interface SavingsProps {
  clients: 'businesses'|'individuals'|null;
  revenue: number;
  intlPercent: number;
  numberTransactions: number;
}

export const calculateCurrentCosts = ( revenue:number, intlPercent:number) => {
  if ( !revenue || !intlPercent) return 0;

  const localPercent = 100-intlPercent
  const intlPortion = revenue * (intlPercent/100);
  const localPortion = revenue * (localPercent/100);
  const currentIntlFees = intlPortion * .044;
  const currentLocalFees = localPortion * .029;

  const totalCurrentFees = currentIntlFees + currentLocalFees;

  return totalCurrentFees;
};

export const formatCurrency = (amt: number):string => {
  const format = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amt);
  return (format.split('.')[0]);
}

interface Calculations {
  amt: number,
  qty: number
}

const calculateInternationalBorderlessFees = ({amt,qty}:Calculations)=>{
  const stdUncappedFee = amt * .02;
  const feesPerAvgPayment = stdUncappedFee <= 200 ? stdUncappedFee : 200;
  return feesPerAvgPayment * qty;
}

const calculateLocalBusinessFees = ({amt,qty}:Calculations) => {
  if (amt > 2000) {
    const aboveCapAmount = amt - 2000;
    const aboveCapFee = aboveCapAmount * .001;
    const totalLargePaymentFee = 5 + aboveCapFee;

    return totalLargePaymentFee * qty;
  }
  const fee = amt * .01;
  return (fee > 5 ? 5 : fee)
}

const calculateLocalIndividualFees =  ({amt,qty}:Calculations) => {
  const stdUncappedFee = amt * .01;
  const feesPerAvgPayment = stdUncappedFee <= 200 ? stdUncappedFee : 200;
  return feesPerAvgPayment * qty;
}

export const calculateBorderlessCost = ({clients, revenue, intlPercent, numberTransactions}:SavingsProps)=> {
  if (!clients || !revenue || !intlPercent || !numberTransactions) return 0;

  const localPercent = 100-intlPercent;
  const avgPaymentSize = revenue/numberTransactions;
  const numberIntlPayments = numberTransactions * (intlPercent/100);
  const numberLocalPayments = (numberTransactions * (localPercent/100));
  
  const borderlessIntlFees = calculateInternationalBorderlessFees({amt: avgPaymentSize, qty: numberIntlPayments});

  const borderlessLocalFees = clients === 'businesses'?
    calculateLocalBusinessFees({amt:avgPaymentSize, qty:numberLocalPayments})
    : calculateLocalIndividualFees({amt: avgPaymentSize, qty: numberLocalPayments});
  
  return borderlessIntlFees + borderlessLocalFees;
};