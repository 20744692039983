import React from 'react';
import styles from './Input.module.scss'
import InputWrapper from './InputWrapper';

interface Props {
  percent: number;
  onChange: (e:any)=>void;
}

const formatNumber = (pct:number):string => {
  return new Intl.NumberFormat('en-US',{style:'percent'}).format(pct/100);
}

const IntlPercentSlider = ({percent,onChange}:Props)=>(
  <InputWrapper
    fieldName='internationalPercent'
    label="How much of your revenue is from international clients?"
    component={(
      <>
        <input
          className={styles.slider}
          id="internationalPercent"
          type="range" 
          min="0" 
          max="100" 
          value={percent}
          onChange={onChange}
        />
        <span className={styles.rangeValue}>{formatNumber(percent)}</span>
      </>
    )}
  />
);

export default IntlPercentSlider;